/* You can add global styles to this file, and also import other style files */

.container
  display: flex
  justify-content: center
  text-align: center
  flex-direction: column
  align-items: center
  .header
    padding: 50px 0 40px
    input
      font-size: 18px
  table
    text-align: center
    td
      padding: 5px 10px

// lato/altezza
$rateoDimensioneCarta: 417/800

.header
  text-align: center
  h1
    margin: 0
  h2
    margin: 0
    font-size: 18px
    font-weight: 100
    color: #333

.btn
  display: inline-block
  cursor: pointer
  padding: 4px 9px
  border: 1px solid
  border-radius: 2px
  background: #f3f3f3
  margin-bottom: 10px

#tavolo
  .carte
    .carta
      background: white
      width: 50px
      height: 50px / $rateoDimensioneCarta
      border: 1px solid
      border-radius: 3px
      margin: 5px 3px
      cursor: pointer
      background-position: center
      background-size: cover

#gioco-classico
  #tavolo
    display: flex
    flex-direction: column
    align-items: center
    padding: 10px 20px
    .carte
      display: flex
      flex-wrap: wrap
      justify-content: center
      max-width: 680px
      .carta

#gioco-geometrico
  #tavolo
    background: transparent
    padding: 50px
    .carte
      position: relative
      width: 200px
      height: 200px
      .carta
        position: absolute
        left: 50%
        top: 50%
        z-index: 10
        transform: translate(-50%,-50%)
        transition: transform 0.2s linear
        &:hover
        &.hide
          opacity: 0
          width: 0
          height: 0
          z-index: -1

#pesca-una-carta
  padding: 0 0 50px

#tema-del-giorno
  padding: 50px 0 50px
  background: #fafafa
  h2
    margin: 0

#carta-pescata
  padding: 40px 0
  background: #eee
  .inner
    display: flex
    max-width: 500px
    .carta
      min-width: 190px
      height: 190px / $rateoDimensioneCarta
      border: 1px solid
      border-radius: 3px
      background-position: center
      background-size: cover
    .content
      padding: 10px 0 0 25px
      text-align: left
      .nome
        font-size: 25px
        margin-bottom: 10px
      .descrizione
        color: #333

.footer
  max-width: 400px
  padding-top: 50px
  margin: 0 auto

  .socials
    display: flex
    align-items: center
    justify-content: center

    a
      padding: 5px 3px
      font-size: 14px
      text-decoration: none
